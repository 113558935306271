import React from 'react';
import loader from "../assets/images/loaders/loader3.gif";
import {Stack} from "@mui/material";

function HomePageLoader() {


    return (
        <Stack sx={{width: '100%'}} justifyContent={"center"} flexDirection={"column"}
               alignItems={"center"}>
            {/*<LinearProgress/>*/}
            <div className='text-center fst-italic fs-5'>Cars are on the way. They will be in a
                few sec...
            </div>
            <img src={loader} alt="loader"/>
        </Stack>
    );
}

export default HomePageLoader;