const UploadForm = (props) => {
    const {onUpload} = props
    const imageHandleChange = (e) => {
        const fileList = Array.from(e.target.files)
        onUpload(fileList)
    }
    
    return (
        <div className="form-control bg-white border-0 ps-0" >
            <div className="d-flex justify-content-start align-items-end w-50 ">
                <div className="w-100">
                    <label className="form-label" htmlFor="file">Select file to upload</label>
                    <input className="form-control" id="file" multiple type="file" onChange={imageHandleChange}/>
                </div>
            </div>
        </div>
    )
}
export default UploadForm
