import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
});
const localStorageData = JSON.parse(localStorage.getItem('PA-profile'))
const initialState = {
    carList: [],
    browseSelector: null,
    browseFilter: {},
    newCar: {},
    carById: {},
    getAllReq: false,
    isAuth:  localStorageData|| null,
    logInError:'',
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
       
        case 'FILTER':
            return {...state, browseFilter: {type: action.payload.type, value: action.payload.value}}
        case 'CREATE_NEW_CAR':
            return {...state, newCar: action.payload}
        case 'GET_ALL_CARS':
            return {...state, carList: action.payload, getAllReq: !state.getAllReq, loading: false}
        case 'GET_CAR_BY_ID':
            return {...state, carById: action.payload, loading: false}
        case 'SET_EDIT_CAR':
                return {...state, carById: {...state.carById,[action.payload.name]: action.payload.value}}
       
        case 'LOADING_ON':
            return {...state, loading: true}
        case 'RESET_CAR_BY_ID':
            return {...state, carById: {}}
    
        case 'LOG_IN_ERROR':
            return {...state, logInError: action.payload}
        case 'LOG_IN':
            localStorage.setItem('PA-profile', JSON.stringify({...action.payload}))
            return {...state, isAuth: action.payload}
        case 'LOG_OUT':
            localStorage.removeItem('PA-profile')
            return {...state, isAuth: null, logInError: ''}
        
        default:
            return state
    }
}

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))
export default store