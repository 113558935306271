export default function BelowFooter(){
    return (
        <div className="container-lg below-footer">
        <div className="below-footer-pas">All rights reserved. © 2014 Premium Auto Sales LLC</div>
        <div className="below-footer-links">
            <span>Terms and conditions of website use</span>
            <span >Privacy policy</span>
        </div>
        </div>
    )
}