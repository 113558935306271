export default function Footer() {
    return (
        <div className="container-lg pe-0 ps-0 shadow">
            <div className="footer">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-3 mb-2">
                        <h3 className="footer-title">welcome to premium auto</h3>
                        <p className="footer-info ">Premium Auto Sales is a Auto Dealer located on the border line of
                            Tiverton Rhode Island and
                            Fall River Massachusetts. Serving the greater Tiverton, RI, Fall River, MA, Westport, MA,
                            Swansea, MA, Dartmouth, MA, Bristol, RI, Little Compton, RI, Portsmouth, RI. Middletown, RI
                            and Newport, RI areas.</p>
                    </div>
                    <div className="col-12 col-lg-3 mb-4">
                        <h3 className="footer-title">hours of operation</h3>
                        <div className="footer-info size">
                            <div className="footer-info-item">
                                <span>Monday</span>
                                <span>9am to 5pm </span>
                            </div>
                            <div className="footer-info-item">
                                <span>Tuesday</span>
                                <span>9am to 5pm </span>
                            </div>
                            <div className="footer-info-item">
                                <span>Wednesday</span>
                                <span>9am to 5pm </span>
                            </div>
                            <div className="footer-info-item">
                                <span>Thursday</span>
                                <span>9am to 5pm </span>
                            </div>
                            <div className="footer-info-item">
                                <span>Friday</span>
                                <span>9am to 5pm </span>
                            </div>
                            <div className="footer-info-item">
                                <span>Saturday</span>
                                <span>9am to 3pm </span>
                            </div>
                            <div className="footer-info-item">
                                <span>Sunday</span>
                                <span> By appointment only</span>
                            </div>
                        
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <iframe title="Google Maps"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d75807.21369100754!2d-71.13603600581052!3d41.67870530536989!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e4ff23b25c2107%3A0x49282ffe8d9a558e!2s32%20Stafford%20Rd%2C%20Tiverton%2C%20RI%2002878!5e0!3m2!1sen!2sus!4v1629333430633!5m2!1sen!2sus"
                                width="100%" height="300" allowFullScreen="" loading="lazy"/>
                    </div>
                </div>
            </div>
        </div>
    )
}