import emailjs from 'emailjs-com';
import {useHistory} from "react-router-dom";
const {REACT_APP_SERVICE_ID, REACT_APP_USER_ID } = process.env

function ContactInputForm(props) {
    const history = useHistory()
    
    const subj = props.subject ? props.subject.year + ' ' + props.subject.made + ' ' + props.subject.model : null
    
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm(`${REACT_APP_SERVICE_ID}`, 'PremiumAuto', e.target, `${REACT_APP_USER_ID}`)
        //emailjs.sendForm('service_hziaph6', 'PremiumAuto', e.target, 'user_TNloTwAV1DNYfOtcYys4O')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
        history.push('/')
    }
  
    return (
        <div className="col-lg-9">
            
            <form className="form-control-sm" onSubmit={sendEmail}>
                <div className="row">
                    <div className=" col-sm-5 order-2 order-sm-1 col-lg-7">
                        <div className="mb-3">
                            <label htmlFor="nameInput" className="form-label">Name (required)</label>
                            <input type="text" className="form-control" id="nameInput" required='true'
                                   aria-describedby="emailHelp" name='user_name'/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email address (required)</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" required='true'
                                   aria-describedby="emailHelp" name='user_email'/>
                        
                        </div>
                        <div className="mb-3">
                            <label htmlFor="subjectInput" className="form-label">Subject</label>
                            <input type="text" className="form-control" id="subjectInput"
                                   aria-describedby="emailHelp" name='subject' value={subj}/>
                        </div>
                        
                        <div/>
                    </div>
                    <div className="col-sm-4  order-1  col-lg-5  order-sm-2">
                        <div className="contact">
                            <h5 className="contact-title">CONTACT US {subj && 'REGARDING THIS LISTING'}</h5>
                        </div>
                        <p>Premium Auto Sales <br/>
                            32 Stafford Road <br/>
                            Tiverton, RI 02878</p>
                        <p><a href="tel:4014001401">Phone : 401.400.1401</a> <br/>
                            Email : drivepremium@gmail.com</p>
                    </div>
                
                </div>
                <div className="mb-3 col-lg-12 order-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Your Message</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" name="message">
                            </textarea>
                    <input className="contact-button"  type="submit" value="Send" />
                </div>
            </form>
        </div>
    )
}

export default ContactInputForm