import {withRouter} from "react-router-dom";
import Accordion from "./Accordion";
import ContactInputForm from "./ContactInputForm";

function ContactForm() {
        return (
        <>
            <div className="container-lg bg-white ps-0 pe-0 shadow ">
                <div className="referral-title "><h2>CONTACT OUR TEAM</h2></div>
            </div>
        <div className="container-lg bg-white pt-4 pb-4 shadow">
            
            <div className="row">
                <Accordion hide={true}/>
                <ContactInputForm/>
            </div>
        
        </div>
        </>
    )
}

export default withRouter(ContactForm)