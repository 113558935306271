import React, {useState} from 'react';
import {Autocomplete, Box, Button, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {carMadeList} from "../../assets/carMadeList";
import {yupResolver} from "@hookform/resolvers/yup";
import {carSchema} from "../../assets/yup";
import UploadForm from "./UploadForm";
import UploadedFile from "./UploadedFile";
import {LazyLoadImage} from "react-lazy-load-image-component";
import placeHolder from "../../assets/images/placeholder.png";
import {useImageViewer} from "react-image-viewer-hook";

const bodyTypeList = ['Convertible', 'Coupe', 'Sedan', 'Hatchback', 'Station Wagon', 'SUV', 'Truck', 'Van/ Minivan'];
const engineSizeList = ['3 Cylinder', '4 Cylinder', '5 Cylinder', '6 Cylinder', '8 Cylinder', 'Hybrid', 'Electric'];
const transmissionsList = ['Automatic', 'Manual']
const fuelTypeList = ['Gas', 'Diesel', 'Electric']
const newCarObj = {
    made: '', model: '', mileage: '', price: '', year: '', bodyType: '', engineSize: '', transmission: '', fuelType: '',
    photoTagText: '', photoTagColor: '', description: ''
}

function CarFormFields(props) {
    const {initialValues, onFinish, button, type} = props
    const [uploadedFiles, setUploadedFiles] = useState([])

    const [deletedPhotos, setDeletedPhotos] = useState([])
    const [photoList, setPhotoList] = useState(initialValues?.photo || [])
    const { getOnClick, ImageViewer } = useImageViewer()
    const defaultValues = initialValues ? initialValues : newCarObj

    const {register, handleSubmit, control, formState: {errors}} = useForm({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: yupResolver(carSchema),
    })

    const onUpload = fileList => {
        setUploadedFiles(uploadedFiles.concat(fileList))
    }

    const deleteSelectedFile = (name) => {
        const newList = uploadedFiles.filter(el => el.name !== name)
        setUploadedFiles(newList)
    }

    const deletePhotoHandler = (id) => {
        const newPhotoList = photoList.filter(el => el._id !== id)
        setPhotoList(newPhotoList)
        setDeletedPhotos([...deletedPhotos, id])
    }

    const onSubmit = (values) => {
        let data = new FormData()
        for (let key in values) {
            if(key==='photo') data.append(`${key}`, JSON.stringify(values[key]) );
            else {
                data.append(`${key}`, values[key] ? values[key]: '')
            }
        }
        uploadedFiles.forEach(file => {
            data.append('photo[]', file, file.name)
        })
        if(type==='edit' ){
            data.append('delPhotos', deletedPhotos)
        }
        onFinish(data)
    }

    // console.log('photoList', photoList)
    // console.log('Deleted List', deletedPhotos)


    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-2 ">
                <div className="col-sm-5 col-lg-4">

                    <Controller
                        control={control}
                        name="made"
                        render={({field: {onChange, value}}) => (
                            <Autocomplete
                                disablePortal
                                id="made"
                                value={value}
                                fullWidth={true}
                                options={carMadeList}
                                onInputChange={(e, value) => onChange(value)}
                                onChange={(_, data) => onChange(data)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        label="Made"
                                        margin={'normal'}
                                        required
                                        error={!!errors.made}
                                        helperText={errors.made?.message}
                                    />
                                )}
                            />
                        )}
                    />

                </div>
                <div className="col-sm-5 col-lg-4 ">
                    <TextField
                        label="Model"
                        margin={'normal'}
                        size="small"
                        required
                        error={!!errors.model}
                        {...register('model')}
                        helperText={errors.model?.message}
                        fullWidth={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-5 col-lg-3">
                    <TextField
                        label="Price"
                        margin={'normal'}
                        type="number"
                        size="small"
                        error={!!errors.price}
                        {...register('price')}
                        helperText={errors.price?.message}
                        fullWidth={true}
                    />
                </div>
                <div className="col-sm-5 col-lg-3 ">
                    <TextField
                        label="Mileage"
                        margin={'normal'}
                        type="number"
                        size="small"
                        error={!!errors.mileage}
                        {...register('mileage')}
                        helperText={errors.mileage?.message}
                        fullWidth={true}
                    />
                </div>
                <div className="col-sm-5 col-lg-3">
                    <TextField
                        label="Year"
                        type="number"
                        margin={'normal'}
                        size="small"
                        error={!!errors.year}
                        {...register('year')}
                        helperText={errors.year?.message}
                        fullWidth={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-5 col-lg-3">
                    <Controller
                        control={control}
                        name="bodyType"
                        render={({field: {onChange, value}}) => (
                            <Autocomplete
                                disablePortal
                                id="bodyType"
                                fullWidth={true}
                                options={bodyTypeList}
                                value={value}
                                onInputChange={(e, value) => onChange(value)}
                                onChange={(_, data) => onChange(data)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Body Type"
                                        margin={'normal'}
                                        size="small"
                                        error={!!errors.bodyType}
                                        helperText={errors.bodyType?.message}
                                    />
                                )}
                            />
                        )}
                    />

                </div>
                <div className="col-sm-5 col-lg-3">
                    <Controller
                        control={control}
                        name="engineSize"
                        render={({field: {onChange, value}}) => (
                            <Autocomplete
                                disablePortal
                                id="engineSize"
                                fullWidth={true}
                                options={engineSizeList}
                                value={value}
                                onInputChange={(e, value) => onChange(value)}
                                onChange={(_, data) => onChange(data)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Engine Size"
                                        margin={'normal'}
                                        size="small"
                                        error={!!errors.engineSize}
                                        helperText={errors.engineSize?.message}
                                    />
                                )}
                            />
                        )}
                    />
                </div>
                <div className="col-sm-5 col-lg-3">
                    <Controller
                        control={control}
                        name="transmission"
                        render={({field: {onChange, value}}) => (
                            <Autocomplete
                                disablePortal
                                id="engineSize"
                                fullWidth={true}
                                options={transmissionsList}
                                value={value}
                                onInputChange={(e, value) => onChange(value)}
                                onChange={(_, data) => onChange(data)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Transmission"
                                        margin={'normal'}
                                        size="small"
                                        error={!!errors.transmission}
                                        helperText={errors.transmission?.message}
                                    />
                                )}
                            />
                        )}
                    />
                </div>
                <div className="col-sm-5 col-lg-3">
                    <Controller
                        control={control}
                        name="fuelType"
                        render={({field: {onChange, value}}) => (
                            <Autocomplete
                                disablePortal
                                id="fuelType"
                                fullWidth={true}
                                options={fuelTypeList}
                                value={value}
                                onInputChange={(e, value) => onChange(value)}
                                onChange={(_, data) => onChange(data)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Fuel Type"
                                        margin={'normal'}
                                        size="small"
                                        error={!!errors.transmission}
                                        helperText={errors.transmission?.message}
                                    />
                                )}
                            />
                        )}
                    />
                </div>
                <div className='col-12 col-lg-4'>
                    <TextField
                        label="Photo Tag Text"
                        margin={'normal'}
                        size="small"
                        {...register('photoTag')}
                        fullWidth={true}
                    />
                </div>
                <div className='col-12 col-lg-1'>
                    <TextField
                        label="Color"
                        margin={'normal'}
                        type={"color"}
                        size="small"
                        {...register('photoTagColor')}
                        fullWidth={true}
                    />
                </div>
            </div>

            <div className="mb-3 col-12 col-lg-10">
                <TextField
                    id="description"
                    multiline
                    minRows={10}
                    maxRows={20}
                    label="Description"
                    margin={'normal'}
                    {...register('description')}
                    fullWidth={true}
                />
            </div>
            <div>
                <UploadForm onUpload={onUpload}/>
                <div>
                    {
                        uploadedFiles.map(el => <div key={el.name}>
                            <UploadedFile
                                file={el}
                                deleteSelectedFile={deleteSelectedFile}
                            />
                        </div>)
                    }
                </div>
            </div>
            <div className="row">
                {
                    type==='edit' && photoList.map(el =>
                        <div key={el._id} className="col-12 col-sm-6 col-md-4 uploaded-item">
                            <a
                                className='col-12 col-lg-4 mt-2 mt-lg-0'
                                key={el._id}
                                href={`${el.original}?auto=compress&cs=tinysrgb&w=1200`}
                                onClick={getOnClick(`${el.original}?auto=compress&cs=tinysrgb&w=1200`)}
                            >
                                <LazyLoadImage
                                    src={el.thumbnail}
                                    alt="car"
                                    placeholderSrc={placeHolder}
                                  //  className="contain"
                                    width="100%"
                                />
                            </a>
                            <Button
                                variant={"outlined"}
                                fullWidth
                                className="mt-2"
                                onClick={() => deletePhotoHandler(el._id)}>
                                DELETE
                            </Button>
                        </div>
                    )
                }
                <ImageViewer />
            </div>


            <button className="contact-button mb-3" type="submit">{button}</button>
        </Box>
    );
}

export default CarFormFields;