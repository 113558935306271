import {withRouter} from "react-router-dom";
import Accordion from "./Accordion";
import {connect} from "react-redux";
import {useEffect} from "react";
import ContactInputForm from "./ContactInputForm";
import {getCarById, resetCarByID} from "../Redux/actions";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeHolder from '../assets/images/placeholder.png'
import {addCommas} from "../assets/helpers";
import {useImageViewer} from "react-image-viewer-hook";
import {Box, LinearProgress} from "@mui/material";
import MyGallery from "./MyGallery";

function SingleCar(props) {
    const {car, getCarById, resetCarByID, loading} = props
    const id = props.match.params.carId
    const {getOnClick, ImageViewer} = useImageViewer()

    useEffect(() => {
        getCarById(id)
        return () => {
            resetCarByID()
        }
    }, [getCarById, resetCarByID, id])

    return (
        <div>
            <div className="container-lg bg-white ps-0 pe-0 shadow referral-title">
                <div>
                    <h2 className='mx-auto'>{car.year} {car.made} {car.model}</h2>
                    <h2 className='mx-auto text-center'> ${addCommas(car.price)}</h2>
                </div>
            </div>
            <div className="container-lg bg-white pt-4 shadow ">
                <div className="row">
                    <Accordion hide={true}/>
                    <div className="col-lg-9">
                        {
                            !car._id || loading ? <Box sx={{width: '100%'}}>
                                    <LinearProgress/>
                                </Box> :

                                <>
                                    {/*<SingleCarSlider items={car.photo}/>*/}
                                    <MyGallery items={car.photo} />

                                    <div className="arrivals mt-10">
                                        <h3 className="arrivals-title">DETAILS</h3>
                                        <div className="arrivals-grey-block"/>
                                    </div>
                                    <div className="row mt-3  ms-1 ms-sm-0 ">
                                        <div className="offset-md-1 col-md-3">
                                            <h4>Price: {car.status !== 'sold' ? `$ ${addCommas(car.price)}` : 'SOLD'}</h4>
                                            <div>Year: {car.year}</div>
                                            <div>Fuel Type: {car.fuelType} </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>Body Type: {car.bodyType}</div>
                                            <div>Engine Size: {car.engineSize}</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>{addCommas(car.mileage)} miles</div>
                                            <div>Tranny: {car.transmission}</div>
                                        </div>
                                    </div>
                                    <div className="des mt-3">{car.description}</div>
                                    <div className="row">
                                        {
                                            car.photo && car.photo.map(el =>
                                                <a
                                                    className='col-12 col-sm-6 col-lg-4 mt-2'
                                                    key={el._id}
                                                    href={`${el.original}?auto=compress&cs=tinysrgb&w=1200`}
                                                    onClick={getOnClick(`${el.original}?auto=compress&cs=tinysrgb&w=1200`)}
                                                >
                                                    <LazyLoadImage
                                                        //   className="asds"
                                                        key={el}
                                                        src={el.thumbnail}
                                                        alt='Car'
                                                        width="100%"
                                                        placeholderSrc={placeHolder}/>
                                                </a>)
                                        }
                                        <ImageViewer/>
                                    </div>
                                    <ContactInputForm subject={car}/>
                                </>
                        }
                    </div>

                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    car: state.carById,
    loading: state.loading,
})

export default withRouter(connect(mapStateToProps, {getCarById, resetCarByID})(SingleCar))