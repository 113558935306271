import {useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";

const manufacturer = ['Acura', 'Audi', 'BMW', 'Buick', 'Cadillac', 'Chevrolet', 'Chrysler', 'Dodge', 'Ford', 'GMC', 'Honda',
    'Hummer', 'Hyundai', 'Infinity', 'Jeep', 'Lexus', 'Lincoln', 'Kia',
    'Mazda', 'Mercury', 'Mitsubishi', 'Nissan', 'Pontiac', 'Saab',  "Saturn", 'Scion', 'Smart', 'Subaru', 'Toyota',
    'Volkswagen', 'Volvo']
const bodyType = ['Convertible', 'Coupe', 'Sedan', 'Hatchback', 'Station Wagon', 'SUV', 'Truck', 'Van/ Minivan']
const engineSize = ['3 Cylinder', '4 Cylinder', '5 Cylinder', '6 Cylinder', '8 Cylinder', 'Hybrid']
const mileage = ['5000-60000', '60000-100000', 'More than 100000']
const modelYear = ['1986-1996', '1997-2000', '2001-2005', '2006-2010', '2011 - present']
const priceRange = ['$1000 - $2500', '$2500 - $5000', '$5000 - $10000', '$10000 - $15000', '$15000 - $20000', '$20000 - $30000', '$30000 - $40000',]
const transmission = ['Automatic', 'Manual']
const dropdowns = [
    {
        name: 'manufacturer',
        status: false
    },
    {
        name: 'body type',
        status: false
    },
    {
        name: 'engine size',
        status: false
    },
    {
        name: 'mileage',
        status: false
    },
    {
        name: 'model year',
        status: false
    },
    {
        name: 'price range',
        status: false
    },
    {
        name: 'transmission',
        status: false
    },
]

function Accordion(props) {

    const isMobileView = window.matchMedia("(max-width: 992px)").matches
    console.log('media', isMobileView)

    const [accordion, setAccordion] = useState(dropdowns)
    const [showOptions, setShowOptions] = useState(!isMobileView)

    const toggle = item => {
        const newAccordion = accordion.map(el => el.name === item ? {...el, status: !el.status} : {
            ...el, status: false
        })
        setAccordion(newAccordion)
    }

    const dropDownItemHandler = (selector, item) => {
        props.getFilter(selector, item)
        history.push(`/browse-search/${selector}:${item}`)
    }

    const browseHandler = () => {
        if (isMobileView) {
            setAccordion(dropdowns)
            setShowOptions(prev => !prev)
        }
    }
    let history = useHistory()

    return (
        <div className={props.hide ? "offset-sm-2 col-sm-8 offset-lg-0 col-lg-3 visibility" :
            "offset-sm-2 col-sm-8 offset-lg-0 col-lg-3"}>
            <div className="browse-menu">
                <div className='d-flex justify-content-between align-items-center browse-title' onClick={browseHandler}>
                    <div >BROWSE BY...</div>
                    {
                        isMobileView &&
                        <div className='browse-click'>{showOptions ? 'click to hide' : 'click to open'}</div>
                    }

                </div>
                {
                    showOptions && <div className="queries">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className={accordion[0].status ? "accordion-button queries-par" : "accordion-button collapsed queries-par"}
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne"
                                        onClick={() => toggle('manufacturer')}>
                                        MANUFACTURER
                                    </button>
                                </h2>
                                <div id="collapseOne"
                                     className={accordion[0].status ? 'accordion-collapse collapse show' : "accordion-collapse collapse"}
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {
                                            manufacturer.map(el => <button
                                                key={el}
                                                className="dropdown-item"
                                                onClick={() => dropDownItemHandler('made', el)}>{el}
                                            </button>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className={accordion[1].status ? "accordion-button queries-par" : "accordion-button collapsed queries-par"}
                                        type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo"
                                        onClick={() => toggle('body type')}>
                                        BODY TYPE
                                    </button>
                                </h2>
                                <div id="collapseTwo"
                                     className={accordion[1].status ? 'accordion-collapse collapse show' : "accordion-collapse collapse"}
                                     aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {
                                            bodyType.map(el => <button className="dropdown-item" key={el}
                                                                       onClick={() => dropDownItemHandler('bodyType', el)}>
                                                {el}</button>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className={accordion[2].status ? "accordion-button queries-par" : "accordion-button collapsed queries-par"}
                                        type="button"
                                        data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseThree"
                                        onClick={() => toggle('engine size')}>
                                        ENGINE SIZE
                                    </button>
                                </h2>
                                <div id="collapseThree"
                                     className={accordion[2].status ? 'accordion-collapse collapse show' : "accordion-collapse collapse"}
                                     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {
                                            engineSize.map(el => <button className="dropdown-item" key={el}
                                                                         onClick={() => dropDownItemHandler('engineSize', el)}>
                                                {el}</button>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className={accordion[3].status ? "accordion-button queries-par" : "accordion-button collapsed queries-par"}
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne"
                                        onClick={() => toggle('mileage')}>
                                        MILEAGE
                                    </button>
                                </h2>
                                <div id="collapseOne"
                                     className={accordion[3].status ? 'accordion-collapse collapse show' : "accordion-collapse collapse"}
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {
                                            mileage.map(el => <button className="dropdown-item" key={el}
                                                                      onClick={() => dropDownItemHandler('mileage', el)}>
                                                {el}</button>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className={accordion[4].status ? "accordion-button queries-par" : "accordion-button collapsed queries-par"}
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne"
                                        onClick={() => toggle('model year')}>
                                        MODEL YEAR
                                    </button>
                                </h2>
                                <div id="collapseOne"
                                     className={accordion[4].status ? 'accordion-collapse collapse show' : "accordion-collapse collapse"}
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {
                                            modelYear.map(el => <button className="dropdown-item" key={el}
                                                                        onClick={() => dropDownItemHandler('modelYear', el)}>
                                                {el}</button>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className={accordion[5].status ? "accordion-button queries-par" : "accordion-button collapsed queries-par"}
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne"
                                        onClick={() => toggle('price range')}>
                                        PRICE RANGE
                                    </button>
                                </h2>
                                <div id="collapseOne"
                                     className={accordion[5].status ? 'accordion-collapse collapse show' : "accordion-collapse collapse"}
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {
                                            priceRange.map(el => <button className="dropdown-item" key={el}
                                                                         onClick={() => dropDownItemHandler('priceRange', el)}>
                                                {el}</button>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className={accordion[6].status ? "accordion-button queries-par" : "accordion-button collapsed queries-par"}
                                        type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne"
                                        onClick={() => toggle('transmission')}>
                                        TRANSMISSION
                                    </button>
                                </h2>
                                <div id="collapseOne"
                                     className={accordion[6].status ? 'accordion-collapse collapse show' : "accordion-collapse collapse"}
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {
                                            transmission.map(el => <button className="dropdown-item" key={el}
                                                                           onClick={() => dropDownItemHandler('transmission', el)}>
                                                {el}</button>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    getFilter: (selector, item) => dispatch({
        type: 'FILTER',
        payload: {
            type: selector,
            value: item
        }
    })
})
export default withRouter(connect(null, mapDispatchToProps)(Accordion))