import axios from "axios";
import decode from 'jwt-decode'

const {REACT_APP_SERVER_URL} = process.env

const API = axios.create({baseURL: `${REACT_APP_SERVER_URL}`})

API.interceptors.request.use((req) => {
    if (localStorage.getItem('PA-profile')) {
        const token = JSON.parse(localStorage.getItem('PA-profile')).token
        const decodedToken = decode(token)
        if (decodedToken.exp * 1000 < new Date().getTime()) {
            logout()
            }
        else req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
});

export function loadingOn(){
    return {
        type:'LOADING_ON'
    }
}
export function addNewCar(car, router) {
    return (dispatch) => {
        dispatch(loadingOn())
        API.post('/car', car)
            .then(() => {
                console.log('new car was successfully added')
                dispatch(getAllCars())
                router.push("/edit-existed-listing")
            })
            .catch(() => console.log('new car was not added'))
    }
}

export function getAllCars() {
    return (dispatch) => {
    dispatch(loadingOn())
        API.get('/car')
            .then(res => {
                dispatch({
                    type: 'GET_ALL_CARS',
                    payload: res.data,
                });
            })
            .catch(() => {
                console.log('could not get cards')
            });
    };
}

export function getCarById(id) {
    return (dispatch) => {
        dispatch(loadingOn())
        API.get(`/car/${id}`)
            .then(res => {
                dispatch({
                    type: 'GET_CAR_BY_ID',
                    payload: res.data,
                });
            })
            .catch(() => {
                console.log('could not get cards')
            });
    };
}

export function deleteCar(carId) {
    return (dispatch) => {
        API.delete(`/car/${carId}`)
            .then((res) => {
                console.log(res.data)
                dispatch(getAllCars());
            })
            .catch(() => {
                console.log('could not delete a car')
            })
    };
}

export function updateCar(car, id, router) {
    return (dispatch) => {
        API.patch(`/car/${id}`, car)
            .then(() => {
                console.log('car was successfully updated')
                dispatch(getCarById(id))
                router.push("/edit-existed-listing")
            })
            .catch(() => console.log('car was not updated'))
    }
}

export function setEditCar(name, value) {
    return (dispatch) => dispatch({
        type: 'SET_EDIT_CAR',
        payload: {name, value}
    })
}

export function login(data) {
    return (dispatch) => {
        API.post('/user/login', data)
            .then((res) => {
                console.log('Login success', res.data)
                dispatch({
                    type: 'LOG_IN',
                    payload: res.data
                })
            })
            .catch((err) => dispatch({
                    type: 'LOG_IN_ERROR',
                    payload: err.response.data
                })
            )
    }
}

export function logout() {
    return (dispatch) => {
        dispatch({
            type: 'LOG_OUT'
        })
    }
}

export function enableAdminUploader() {
    return (dispatch) => {
        dispatch({
            type: 'LOADING_ON'
        })
    }
}

export function resetCarByID() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_CAR_BY_ID'
        })
    }
}