import {withRouter} from "react-router-dom";
import Accordion from "./Accordion";
import CarsCard from "./CarsCard";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getAllCars} from "../Redux/actions";


function BrowseSearch(props) {
    const {carList, getAllCars, browseFilter} = props

    const mediaSize = window.matchMedia("(max-width: 992px)")
    console.log('media', mediaSize)

   
    useEffect(() => {
        getAllCars()
    }, [getAllCars]);
    
    let filteredCars
    switch(browseFilter.type) {
        case 'made':
        case 'transmission':
        case 'bodyType':
        case 'fuelType':
        case 'engineSize':  filteredCars = carList.filter(el=>el[browseFilter.type]===browseFilter.value)
            break
        case 'mileage': switch(browseFilter.value){
            case '5000-60000': filteredCars = carList.filter(el=>+el.mileage<=60000 && +el.mileage>=5000)
                break
            case '60000-100000': filteredCars = carList.filter(el=>+el.mileage<=100000 && +el.mileage > 60000)
                break
            default: filteredCars = carList.filter(el=>+el.mileage > 100000)
        }
        break
        case 'modelYear': switch(browseFilter.value){
            case '1986-1996': filteredCars = carList.filter(el=>+el.year<=1996)
                break
            case '1997-2000': filteredCars = carList.filter(el=>+el.year<=2000 && +el.year >= 1997)
                break
            case '2001-2005': filteredCars = carList.filter(el=>+el.year<=2005 && +el.year >= 2001)
                break
            case '2006-2010': filteredCars = carList.filter(el=>+el.year<=2010 && +el.year >= 2006)
                break
            default: filteredCars = carList.filter(el=>+el.year > 2010)
        }
            break
        case 'priceRange': switch(browseFilter.value){
            case '$1000 - $2500': filteredCars = carList.filter(el=>+el.price<=2500)
                break
            case '$2500 - $5000': filteredCars = carList.filter(el=>+el.price<=5000 && +el.price >=2500)
                break
            case '$5000 - $10000': filteredCars = carList.filter(el=>+el.price<=10000 && +el.price >=5000)
                break
            case '$10000 - $15000': filteredCars = carList.filter(el=>+el.price<=15000 && +el.price >= 10000)
                break
            case '$15000 - $20000': filteredCars = carList.filter(el=>+el.price<=20000 && +el.price >= 15000)
                break
            case '$20000 - $30000': filteredCars = carList.filter(el=>+el.price<=30000 && +el.price >= 20000)
                break
            default: filteredCars = carList.filter(el=>+el.price > 30000)
        }
            break
        default: filteredCars= carList
    }
    
    const [filterOption, setFilterOption] = useState('priceDown')
    const filteredInventoryList = () => {
        switch (filterOption) {
            case 'priceDown': return filteredCars.sort((a, b) => +b.price - +a.price)
            case 'modelDown': return filteredCars.sort((a, b) => +b.year - +a.year)
            case 'mileageDown': return filteredCars.sort((a, b) => +b.mileage - +a.mileage)
            
            case 'priceUp': return filteredCars.sort((a, b) => +a.price - +b.price)
            case 'modelUp': return filteredCars.sort((a, b) => +a.year - +b.year)
            case 'mileageUp': return filteredCars.sort((a, b) => +a.mileage - +b.mileage)
            // case 'dateDown': return cars.sort((a, b) => b.da - a.price)
            default: return filteredCars
        }
    }
    
    return (
        <div>
            <div className="container-lg bg-white ps-0 pe-0 shadow ">
                <div className="referral-title"><h2>BROWSE BY: <br/> {browseFilter.type? `${browseFilter.type}: ${browseFilter.value}`:
                    'Chose browse filter'}</h2></div>
                <div className="inventory">
                <form className="inventory-dropdown">
                    <select className="form-select" aria-label="Default select example"
                            onClick={(e)=>setFilterOption(e.target.value)}>
                        <option value="priceDown" selected>Price descending</option>
                        <option value="priceUp">Price ascending</option>
                        <option value="dateDown">Date descending</option>
                        <option value="dateUp">Date ascending</option>
                        <option value="modelDown">Model year descending</option>
                        <option value="modelUp">Model year ascending</option>
                        <option value="mileageDown">Mileage descending</option>
                        <option value="mileageUp">Mileage ascending</option>
                    </select>
                </form>
                </div>
            </div>
            <div className="container-lg bg-white pt-4 shadow ">
                <div className="row">
                    <Accordion/>
                    <div className="col-lg-9">
                        <div className="row">
                            {
                                filteredInventoryList().map(el=> <CarsCard  key={el._id} car={el}/>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    carList: state.carList,
    browseFilter: state.browseFilter
})
export default withRouter(connect(mapStateToProps, {getAllCars})(BrowseSearch))