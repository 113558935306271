import {Link, withRouter} from "react-router-dom"
import DeleteModal from "./DeleteModal";
import  noImage from '../../assets/images/no-image.png'
import {addCommas} from "../../assets/helpers";

function GarageListItem(props) {
    const {car} = props
    const thumbnailImg = car.photo[0]?.thumbnail || noImage

    return (
        <div className="list-item">
            <Link to={`/edit-selected-car/${car._id}`}>
                <img src={thumbnailImg} alt={car.model}/>
            </Link>
            
            <div className="list-item-description">
                <h4>{car.year} {car.made} {car.model}</h4>
                <h5>Price: ${addCommas(car.price)}</h5>
            </div>
            <DeleteModal car={car}
                         buttonLabel="DELETE"/>
        </div>
    )
}

export default withRouter(GarageListItem)