import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getCarById, resetCarByID, updateCar} from "../../Redux/actions";
import {useEffect} from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';
import CarFormFields from "./CarFormFields";
import {Box, LinearProgress} from "@mui/material";

function EditSelectedCar(props) {

    const {editCar, updateCar, getCarById, loading, resetCarByID} = props
    const id = props.match.params.carId

    useEffect(() => {
        getCarById(id)
        return () => {
            resetCarByID()
        }
    }, [getCarById, resetCarByID, id]);

    let history = useHistory()

    function editListing(data) {
        updateCar(data, id, history)
    }

    return (
        <div>
            <div className="container-lg bg-white ps-0 pe-0 shadow ">
                <div className="referral-title ">
                    <h2>ADMIN PAGE</h2>
                </div>
                <div className="ms-3 me-3">
                    <div className="arrivals ms-2  ">
                        <h3 className="arrivals-title">EDIT CAR INFORMATION</h3>
                        <div className="arrivals-grey-block"/>
                    </div>

                    {
                        !editCar._id || loading ? (
                            <Box sx={{width: '100%'}}>
                                <LinearProgress/>
                            </Box>
                        ) : (
                            <CarFormFields
                                initialValues={{...editCar}}
                                onFinish={editListing}
                                button='Save Changes'
                                type='edit'
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    editCar: state.carById,
    loading: state.loading
})

export default withRouter(connect(mapStateToProps,
    {getCarById, updateCar, resetCarByID})(EditSelectedCar))