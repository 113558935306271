import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import  noImage from "../assets/images/no-image.png";
import {addCommas} from "../assets/helpers";

function CarsCard(props) {
    const {car} = props

    return (

        <div className="col-sm-6 col-lg-4 car-card">
            <Link to={`/singleCar/${car._id}`} className='car-card-wrapper'>
                <LazyLoadImage
                    width={'100%'}
                    className="car-card-wrapper-photo"
                    src={car.photo[0]?.thumbnail || noImage}
                    alt="some car"
                    effect='blur'
                />
                {
                    car.photoTag &&
                    <div className='car-card-wrapper-tag'>
                        <div className='car-card-wrapper-tag-text'>{car.photoTag}</div>
                        <div className='car-card-wrapper-tag-color' style={{backgroundColor: `${car.photoTagColor}`}}/>
                    </div>
                }
            </Link>
            <h3 className="car-card-price">{car.status === 'available' ? `$ ${ addCommas((car.price))}` : `${car.status.toUpperCase()}`}</h3>
            <div className="car-card-made">{car.year} {car.made} {car.model}</div>
            <div className="car-card-descr">{addCommas(car.mileage)} miles | {car.transmission} | {car.engineSize}</div>

        </div>
    )
}

export default withRouter(connect(null)(CarsCard))