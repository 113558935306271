import {withRouter} from "react-router-dom";
import Slider from "./Slider";
import Accordion from "./Accordion";
import CarsCard from "./CarsCard";
import {connect} from "react-redux";
import {useEffect} from "react";
import {getAllCars} from "../Redux/actions";

import HomePageLoader from "./HomePageLoader";

function Home(props) {

    const {getAllCars, loading} = props

    useEffect(() => {
        getAllCars()
    }, [getAllCars]);

    return (
        <div>
            <Slider/>
            <div className="container-lg bg-white pt-2 pt-md-4 shadow">
                <hr/>
                <div className="row">
                    <Accordion/>
                    <div className="offset-md-1 col-md-10 offset-lg-0 col-lg-9">
                        <div className="arrivals">
                            <h3 className="arrivals-title">NEW ARRIVALS</h3>
                            <div className="arrivals-grey-block"></div>
                        </div>
                        <div className="row">
                            {
                                loading ?
                                    <HomePageLoader/> : props.carList.map(el => <CarsCard key={el.id} car={el}/>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.loading,
    carList: state.carList
})
export default withRouter(connect(mapStateToProps, {getAllCars})(Home))