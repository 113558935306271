import {withRouter} from "react-router-dom";
import {getAllCars} from "../../Redux/actions";
import {connect} from "react-redux";
import {useEffect} from "react";
import GarageListItem from "./GarageListItem";

function EditGarage(props) {
    const {carList, getAllCars} = props

    useEffect(() => {
        getAllCars()
    }, [getAllCars]);

    return (
        <div className="container-lg bg-white ps-0 pe-0 pt-2 pb-2 shadow minVh46">
            <div className="arrivals ms-3 me-3 ">
                <h3 className="arrivals-title">ALL CARS LISTING</h3>
                <div className="arrivals-grey-block"/>
            </div>
            {
                carList.map(el => <GarageListItem key={el._id} car={el}/>)
            }
        </div>
    )
}

const mapStateToProps = state => ({
    carList: state.carList,
})
export default withRouter(connect(mapStateToProps, {getAllCars})(EditGarage))