import './App.scss';
import Footer from "./modules/Footer";
import BelowFooter from "./modules/BelowFooter";
import Header from "./modules/Header";
import {BrowserRouter as Router, BrowserRouter, Link, Route, Switch} from "react-router-dom";
import Home from "./modules/Home";
import ContactForm from "./modules/ContactForm";
import Inventory from "./modules/Inventory";
import SingleCar from "./modules/SingleCar";
import BrowseSearch from "./modules/BrowseSearch";
import CreateNewListing from "./modules/Administration/CreateListingForm";
import EditListingForm from "./modules/Administration/Garage";
import EditSelectedCar from "./modules/Administration/EditSelectedCar";
import SignIn from "./modules/SignIn";
import {connect} from "react-redux";
import ScrollToTop from "./modules/ScrollToTop";

function App({isAuth}) {
    return (
        <BrowserRouter>
            <Router>
                <ScrollToTop/>
                <Header/>
                <div className=" container-lg gray-line"/>
                <div className="container-lg bg-white">
                    <div className="bg-white">
                        <div className="menubar">
                            <Link to="/" className="menubar-links">HOME</Link>
                            <Link to="/inventory" className="menubar-links">INVENTORY</Link>
                            <Link to="/contact-us" className="menubar-links">CONTACT PREMIUM</Link>
                            {
                                isAuth && <Link to="/create-new-listing" className="menubar-links">NEW LISTING</Link>
                            }
                            {
                                isAuth && <Link to="/edit-existed-listing" className="menubar-links">EDIT LISTING</Link>
                            }
                        </div>
                    </div>
                
                </div>
                <div className=" container-lg gray-line"/>
                <Switch>
                    <Route path="/inventory">
                        <Inventory/>
                    </Route>
                    <Route path="/browse-search/:carDescription">
                        <BrowseSearch/>
                    </Route>
                    <Route path="/contact-us">
                        <ContactForm/>
                    </Route>
                    {
                        isAuth && <Route path="/create-new-listing">
                            <CreateNewListing/>
                        </Route>
                    }
                    {
                        isAuth && <Route path="/edit-existed-listing">
                            <EditListingForm/>
                        </Route>
                    }
                    {
                        isAuth && <Route path="/edit-selected-car/:carId">
                            <EditSelectedCar/>
                        </Route>
                    }
                    <Route path="/singleCar/:carId">
                        <SingleCar/>
                    </Route>
                    <Route path="/sign-in">
                        <SignIn/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
                <div className=" container-lg gray-line"/>
                <Footer/>
                <BelowFooter/>
            </Router>
        </BrowserRouter>
    );
}

const mapStateToProps = state => ({
    isAuth: state.isAuth
})
export default connect(mapStateToProps)(App);
