import fb from '../assets/images/socialicons/facebook.svg'
import insta from '../assets/images/socialicons/instagram.svg'
import logo from '../assets/images/premiumAuto.png'
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {logout} from "../Redux/actions";

function Header(props) {
    const {isAuth, logout} = props
    return (
        <div className="container-lg  bg-white shadow">
            <div className="navbar">
                <div className="navbar-wrapper">
                    <div className="navbar-wrapper-item"><a
                        href="https://drivepremium.com/general-info/">General Info</a></div>
                    <div className="navbar-wrapper-item">
                        {
                            !isAuth && <Link to="/sign-in">Login</Link>
                        }
                        {
                            isAuth && <button onClick={() => logout()}>Logout</button>
                        }
                    </div>
                </div>
            </div>

            <div className="mt-lg-4">
                <div className="row pb-sm-3">
                    <div className="col-12 col-sm-6">
                        <a className='main_logo' href="https://drivepremium.com">
                            <img src={logo} alt=""/>
                        </a>
                    </div>
                    <div className="col-12 col-sm-6">
                        <p className='headertext'>
                            <a href="tel:4014001401" rel="noreferrer">Phone:(401) 400-1401</a>
                            <br/>
                            <a href="http://maps.google.com/?q=32 Stafford Road, Tiverton, RI, 02878" target='_blank' rel="noreferrer">32
                                Stafford Road, Tiverton, RI, 02878</a>
                        </p>
                        <div className='social-wrapper'>

                            <a className="social-wrapper-item" href="https://www.facebook.com/drivepremium/">
                                <img src={fb} alt="fb"/>
                            </a>
                            <a className="social-wrapper-item" href="https://www.instagram.com/drivepremium/">
                                <img src={insta} alt="fb"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuth: state.isAuth
})
export default withRouter(connect(mapStateToProps, {logout})(Header))