import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {connect} from "react-redux";
import {deleteCar} from "../../Redux/actions";

const DeleteModal = (props) => {
    const {buttonLabel, deleteCar, car} = props;
    
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    
    const handleClick = () => {
        deleteCar(car._id)
        toggle()
    }
    
    return (
        <div>
            <Button outline onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Дядя, а ты точно уверен, что хочешь удалить этот листинг? <br/>Фотки тоже все удалятся</ModalHeader>
                <ModalBody>
                    Car: {car.year} {car.made} {car.model}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleClick}>Пох, удаляй</Button>{' '}
                    <Button color="danger" onClick={toggle}>Не, в член</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default connect(null, {deleteCar})(DeleteModal);