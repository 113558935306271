import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addNewCar} from "../../Redux/actions";
import uploader from "../../assets/images/loaders/uploading.gif";
import CarFormFields from "./CarFormFields";


function CreateListingForm() {

    const loading = useSelector(state => state.loading)
    const history = useHistory()
    const dispatch = useDispatch()

    function createNewListing(data) {
        dispatch(addNewCar(data, history))
    }

    return (
        <div className="create-new-listing">
            <div className="container-lg bg-white ps-0 pe-0 shadow ">
                <div className="referral-title ">
                    <h2>ADMIN PAGE</h2>
                </div>

                <div className="ms-3 me-3">
                    <div className="arrivals ms-2  ">
                        <h3 className="arrivals-title">CREATE NEW LISTING</h3>
                        <div className="arrivals-grey-block"/>
                    </div>

                    <CarFormFields onFinish={createNewListing} button='Add listing'/>

                    {
                        loading && <div className="admin-uploader">
                            <img src={uploader} alt="uploader"/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CreateListingForm