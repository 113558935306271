import nextButton from "../assets/images/next.png";
import prevButton from "../assets/images/prev.png";
import {useState} from "react";
import {
    Carousel,
    CarouselItem,
} from 'reactstrap';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import noImage from "../assets/images/no-image.png"
import {addCommas} from "../assets/helpers";


function Slider(props) {
    
    const {carList} = props
    const items = carList.filter((el, i) => i <= 5)
    
    
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    
    
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
    
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
    
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }
    
    return (
        <Carousel className=" container-lg bg-light ps-0 pe-0 slider shadow "
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}>
            {
                items.map((item) =>
                    <CarouselItem className="carousel-item"
                                  onExiting={() => setAnimating(true)}
                                  onExited={() => setAnimating(false)}
                                  key={item.src}>
                        
                        <img className="carousel-item-img" src={item.photo[0]?.original || noImage}
                             alt={item.made}/>
                        <div className="carousel-item-info">
                            <h3 className="title">{item.year} {item.made} {item.model}</h3>
                            <h6 className="miles">{addCommas(item.mileage)} miles
                                | {item.transmission} | {item.engineSize}</h6>
                            <h2 className="price">$ {addCommas(item.price)}</h2>
                            <Link to={`/singleCar/${item._id}`}>
                                <button>Details</button>
                            </Link>
                        </div>
                        <button className="prev-button" onClick={previous}>
                            <img src={prevButton} onClick={previous} alt=""/>
                        </button>
                        <button className="next-button" onClick={next}>
                            <img src={nextButton} alt=""/>
                        </button>
                        <div className="indicators-group">
                            {
                                items.map((el, i) =>
                                    <button className={activeIndex === i ? 'active-button' : null}
                                            onClick={() => goToIndex(i)}/>)
                            }
                        </div>
                    </CarouselItem>
                )
            }
        </Carousel>
    );
}

const mapStateToProps = state => ({
    carList: state.carList
})

export default withRouter(connect(mapStateToProps)(Slider));
