import {connect} from "react-redux";
import {login} from "../Redux/actions";
import {useEffect, useState} from "react";
import zvuk from '../assets/zvuk.mp3';
import useSound from "use-sound";
import {useHistory} from "react-router-dom";


function SignIn(props) {
    const [play] = useSound(zvuk);
    const {login, isAuth, logInError} = props
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const history = useHistory()

    useEffect(() => {
       if(isAuth) {
           play()
           setTimeout(()=>{
               history.push("/edit-existed-listing")
           }, 3000)
       }
      }, [isAuth, play, history])
    
    const signInHandler = (e) => {
        e.preventDefault()
        const data = {email, password}
        login(data)
    };
    
    return (
        <div className="container-lg bg-white px-0 shadow">
            <div className="row">
                <div className="col-10 offset-1 col-md-4 offset-md-4">
                    {
                        !isAuth && <form className="sign-in">
                            <h2>Sign In your account</h2>
                                <div className="error-description">{logInError}</div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1"
                                       aria-describedby="emailHelp" value={email}
                                       onChange={(e) => setEmail(e.target.value)}/>
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1"
                                       value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <button type="submit" className="btn btn-primary d-block mx-auto" onClick={signInHandler}>Sign in</button>
                        </form>
                    }
                    {
                        isAuth && <div className="greeting">
                            <h2>Welcome to Premium Autosales, <br/> Motherfucker!</h2>
                        </div>
                    }
                </div>
            </div>
        </div>
    
    );
}

const mapStateToProps = state => ({
    isAuth: state.isAuth,
    logInError: state.logInError
})
export default connect(mapStateToProps, {login})(SignIn)