import delImage from '../../assets/images/download.jpg'

function UploadedFile(props) {
    const {file, deleteSelectedFile} = props
    return (
        <div className="uploaded-file">
            <div className="uploaded-file-wrapper">
                <div>{file.name}</div>
                <img src={URL.createObjectURL(file)} alt="item.name"/>
            
            </div>
            <button onClick={()=>deleteSelectedFile(file.name)}>
                <img src={delImage} alt="delete"/>
            </button>
        </div>
    )
}

export default UploadedFile