import {withRouter} from "react-router-dom";
import Accordion from "./Accordion";
import CarsCard from "./CarsCard";
import {useState} from "react";
import {connect} from "react-redux";

function Inventory({cars}) {
   
    const [filterOption, setFilterOption] = useState('priceDown')
    const filteredInventoryList = () => {
        switch (filterOption) {
            case 'priceDown': return cars.sort((a, b) => +b.price - +a.price)
            case 'modelDown': return cars.sort((a, b) => +b.year - +a.year)
            case 'mileageDown': return cars.sort((a, b) => +b.mileage - +a.mileage)
            
            case 'priceUp': return cars.sort((a, b) => +a.price - +b.price)
            case 'modelUp': return cars.sort((a, b) => +a.year - +b.year)
            case 'mileageUp': return cars.sort((a, b) => +a.mileage - +b.mileage)
            // case 'dateDown': return cars.sort((a, b) => b.da - a.price)
            default: return cars
        }
    }
    
    return (
        <>
            <div className="container-lg bg-white ps-0 pe-0 shadow ">
                <div className="referral-title"><h2>INVENTORY ({cars.length})</h2></div>
                <div className="inventory">
                <form className="inventory-dropdown">
                    <select className="form-select" aria-label="Default select example"
                            onClick={(e)=>setFilterOption(e.target.value)}>
                        <option value="priceDown" selected>Price descending</option>
                        <option value="priceUp">Price ascending</option>
                        <option value="dateDown">Date descending</option>
                        <option value="dateUp">Date ascending</option>
                        <option value="modelDown">Model year descending</option>
                        <option value="modelUp">Model year ascending</option>
                        <option value="mileageDown">Mileage descending</option>
                        <option value="mileageUp">Mileage ascending</option>
                    </select>
                </form>
                </div>
            </div>
            <div className="container-lg bg-white pt-sm-2 pt-lg-4 shadow ">
                <div className="row">
                    <Accordion/>
                    <div className="col-lg-9">
                        <div className="row">
                            {
                                filteredInventoryList().map(el=> <CarsCard key={el.id} car={el}/>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    cars: state.carList
})
export default withRouter(connect(mapStateToProps)(Inventory))